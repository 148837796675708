import cryptoJs from 'crypto-js'

// 换肤加class函数
export function toggleClass(element, className) {
	console.log(element,"element")
	console.log(className,"className")
    if (!element || !className) {
      return;
    }
    element.className = className;
}

//自适应字体配置
const px2remLoader = {
  loader: 'px2rem-loader',
  options: {
      remUnit: 37.5   //设计稿的宽度 除以 10，现阶段一般设计稿的宽度都为750px。如果基于iPhone5设计则为32.0(320 / 10 = 32)
  }
}
function generateLoaders (loader, loaderOptions) {
  const loaders = options.usePostCSS ? [cssLoader, postcssLoader, px2remLoader] : [cssLoader, px2remLoader]
/**
*其余的不用改
*/
}

// 加密
let keyOne = 'YIGLMApGCSqGSIb3'
export default {
  //加密函数
  encrypto (word) {
    if (word instanceof Object) {
        word = JSON.stringify(word)
    }
    let encrypted = cryptoJs.AES.encrypt(
      cryptoJs.enc.Utf8.parse(word), 
      cryptoJs.enc.Utf8.parse(keyOne), 
      { 
        mode:cryptoJs.mode.ECB,
        padding: cryptoJs.pad.Pkcs7 
      });
    return encrypted.toString();
  },
  // 解密函數
  decrypt (word) {
    let key = cryptoJs.enc.Hex.parse(keyOne)
    let dec = cryptoJs.AES.decrypt(cryptoJs.format.Hex.parse(word), key, {
      // vi: vi
      mode: cryptoJs.mode.ECB,
      padding: cryptoJs.pad.Pkcs7
    })
    let decData = cryptoJs.enc.Utf8.stringify(dec)
    return decData
  }
}